







































































































































































import Vue from 'vue'
import { ElTable } from 'element-ui/types/table'
import { ApiResponse, Invoice, InvoiceItem, Product, Rate } from '@/interfaces'
import { mapState } from 'vuex'

export default Vue.extend({
  props: {
    invoiceData: {
      type: Object as () => Invoice,
      required: false,
      default: () => {
        return {
          id: null,
          structureId: null,
          type: '' as string | null,
          state: 'proforma',
          items: [] as InvoiceItem[],
        }
      },
    },
    parentProducts: {
      type: Array,
      required: false,
      default: () => [] as Product[],
    },
    vatRates: {
      type: Array,
      required: false,
      default: () => [] as Rate[],
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      products: [] as Product[],
      items: [] as InvoiceItem[],
      lastPosition: 1,
      totalEndPrice: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    invoiceData: {
      deep: true,
      handler(newVal) {
        if (newVal.items) {
          this.items = newVal.items
        }
      },
    },
    parentProducts: {
      handler(newVal) {
        if (newVal) {
          this.products = newVal
        }
      },
    },
    items: {
      handler(newVal) {
        if (newVal) {
          if (newVal.length > 1) {
            // The second from last item is the one containing the proper position,
            // as the last item is the "empty" one created by createNewInvoiceItem()
            const lastItemPos = newVal[newVal.length - 2].position

            // Incrementing last item position
            this.lastPosition = lastItemPos + 1
          }
          if (newVal.length > 0) {
            this.totalEndPrice = newVal
              .map((item: InvoiceItem) => {
                return item && item.type === 'lot' && item?.totalIncludingTaxes
                  ? parseInt(item.totalIncludingTaxes)
                  : 0
              })
              .reduce((accumulator: number, itemEndPrice: number) => {
                // if invoice type is decompte, totalEndPrice must be negative
                return this.invoiceData?.type === 'decompte' ? accumulator - itemEndPrice : accumulator + itemEndPrice
              }, 0)
          }
        } else {
          // Setting last item position
          this.lastPosition = 1
        }
      },
    },
  },
  methods: {
    isBuyerItem(item: InvoiceItem) {
      return item.type === 'fixe_buyer' || item.type === 'percent_buyer'
    },
    isSellerItem(item: InvoiceItem, allowClassicFee = true) {
      if (!allowClassicFee) {
        return item.type === 'fixe_seller' || item.type === 'percent_seller'
      }
      return item.type === 'fixe_seller' || item.type === 'percent_seller' || item.type === 'frais'
    },
    canEditItem(index: number) {
      const item = this.items ? this.items[index] : null
      return item?.isEditable
    },
    addInvoiceItem(event: Event | null) {
      if (this.items && this.items.length > 0) {
        const hasEditableItem = this.items.filter((item) => item.isEditable === true)
        if (hasEditableItem.length > 0) {
          const addNewLine = event ? 'new' : ''
          if (!this.invoiceData.id) {
            this.$emit('storeInvoice', 'invoice_item', addNewLine)
          } else {
            this.$emit('updateInvoice', 'invoice_item', addNewLine)
          }
        } else {
          this.createNewInvoiceItem()
        }
      } else {
        this.createNewInvoiceItem()
      }
    },
    createNewInvoiceItem() {
      const newInvoiceItem: InvoiceItem = {
        label: '',
        type: null,
        quantity: 0,
        priceExcludingTaxes: '0',
        tvaRate: '0',
        priceIncludingTaxes: '',
        accountingAccount: '',
        isEditable: true,
        isExpanded: true,
        opaque: null,
      }
      this.$emit('pushInvoiceItem', newInvoiceItem)
    },
    loadProductInfo(index: number, searchProductId: number) {
      const selectedProduct = this.products.find((product) => product.id === searchProductId)
      if (selectedProduct) {
        let invoiceItem = {
          label: selectedProduct?.name,
          type: selectedProduct?.type,
          quantity: 1,
          priceExcludingTaxes: selectedProduct?.unitPriceExcludingTaxes,
          tvaRate: selectedProduct?.tvaRate,
          accountingAccount: selectedProduct?.accountingAccount,
          isEditable: true,
          isExpanded: false,
          position: this.lastPosition,
          opaque: selectedProduct?.opaque,
        } as InvoiceItem
        let newQuantity =
          !this.isSellerItem(invoiceItem, false) && this.invoiceData.type === 'decompte'
            ? -this.totalEndPrice
            : this.totalEndPrice
        if (this.items && this.items[index]) {
          if (this.items[index].id) {
            invoiceItem = {
              ...invoiceItem,
              id: this.items[index].id,
            }
            newQuantity = this.items[index].quantity ?? 1
          }
          if (invoiceItem.type === 'percent_buyer' || invoiceItem.type?.includes('_seller')) {
            invoiceItem = {
              ...invoiceItem,
              quantity: newQuantity,
            }
          }
          this.$emit('spliceInvoiceItem', invoiceItem, index)
        } else {
          if (invoiceItem.type === 'percent_buyer' || invoiceItem.type?.includes('_seller')) {
            invoiceItem = {
              ...invoiceItem,
              quantity: newQuantity,
            }
          }
          this.$emit('pushInvoiceItem', invoiceItem)
        }
        // this.loading = true
        // this.$api
        //   .post('/invoice-item/update-total', this.invoiceData.items)
        //   .then((response) => {
        //     const apiResponse = response.data as ApiResponse
        //     this.$emit('getInvoiceItemTotals', apiResponse.data.items, '', index)
        //     this.$emit('getInvoiceTotals', apiResponse.data)
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })
      }
    },
    changeInvoiceItemState(index: number, state: string, row: InvoiceItem) {
      if (this.items) {
        const item = this.items[index]
        const hasEditableItem = this.items?.filter((item) => item.isEditable === true)
        if (hasEditableItem.length > 0 && hasEditableItem[0] !== item) return

        if (item && item.quantity) {
          if (state === 'isEditable' && !item.isEditable) {
            item.isEditable = true
          } else if (state === 'isNonEditable' && item.isEditable) {
            item.isEditable = false
            if (!item.id && !this.invoiceData.id) {
              this.$emit('storeInvoice', 'invoice_item')
            } else {
              this.$emit('updateInvoice', 'invoice_item')
            }
          }
          this.$emit('spliceInvoiceItem', item, index)
          if (item.isExpanded) {
            ;(this.$refs.invoicesItemsTable as ElTable).toggleRowExpansion(row, true)
          }
        }
      }
    },
    updateInvoiceItemTotals(index: number, data: InvoiceItem) {
      this.$emit('spliceInvoiceItem', data, index)
      this.loading = true
      this.$api
        .post('/invoice-item/update-total', this.items)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.$emit('getInvoiceItemTotals', apiResponse.data.items, '', index)
          this.$emit('getInvoiceTotals', apiResponse.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteInvoiceItem(index: number, itemId: number) {
      if (!itemId) {
        this.$emit('spliceInvoiceItem', null, index)
      } else {
        const editableItemIndex = this.items?.findIndex((item) => item.isEditable && item.quantity !== 0)
        if (editableItemIndex && editableItemIndex > -1 && this.items) {
          if (this.items[editableItemIndex].id !== itemId) return
        }
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer cette ligne de facture ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            const id = this.invoiceData.id
            this.busy = true
            this.$api.delete(`/invoice/${id}/invoice-item/${itemId}`).then((response) => {
              const apiResponse = response.data as ApiResponse
              this.$emit('refreshInvoice', id)
              // this.$emit('updateInvoiceItems', apiResponse.data.invoice.items)
              // this.$emit('getInvoiceItemTotals', apiResponse.data.totals.items, '', index)
              // this.$emit('getInvoiceTotals', apiResponse.data.totals)
              // this.$notify({
              //   type: 'success',
              //   title: 'Succès',
              //   message: 'Opération réalisée avec succès !',
              // })
            })
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    clearInvoiceItem(index: number) {
      const clearedItem: InvoiceItem = this.items ? this.items[index] : {}
      clearedItem.label = ''
      clearedItem.isEditable = true
      clearedItem.type = null
      this.$emit('spliceInvoiceItem', clearedItem, index)
    },
    expandInvoiceItem(expandedItem: InvoiceItem) {
      this.items?.map((item) => {
        if (item.id === expandedItem.id) {
          item.isExpanded = !item.isExpanded
          return item
        }
      })
    },
  },
})
